<template>
  <div class="subvalueAdded">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>增值服务</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/invoicing" title="开票服务">开票服务</a>
        <a href="/baseHome/valueAdded" title="增值服务" class="on">增值服务</a>
        <a href="/baseHome/ourservice" title="我们的服务">我们的服务</a>
      </div>
    </div>
    <div class="sub-title">增值服务</div>
    <div class="wrapper valueAdded">
      <dl>
        <dt><img src="../../assets/images/va1.jpg"></dt>
        <dd><h3>油品服务</h3><div class="cont">提供电子油卡、在线数据、外请车结算卡、自有加油卡</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va2.jpg"></dt>
        <dd><h3>保险服务</h3><div class="cont">对货投保<br>对人投保<br>对车投保</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va3.jpg"></dt>
        <dd><h3>金融服务</h3><div class="cont">为物流产业提供金融服务，以“人、车、货”为核心服务对象，打造独特的物流全场景金融支持服务</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va4.jpg"></dt>
        <dd><h3>ETC服务</h3><div class="cont">省钱、省时、省心、省力</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va5.jpg"></dt>
        <dd><h3>税筹服务</h3><div class="cont">解决个体司机分散。代开票困难的难题，减少企业交纳所得税，为个体司机提供合规发票</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va6.jpg"></dt>
        <dd><h3>整车销售</h3><div class="cont">提供电子油卡、在线数据、外请车结算卡、自有加油卡</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va7.jpg"></dt>
        <dd><h3>车辆修理</h3><div class="cont">为用户创造价值，压缩成本提高效率。</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va8.jpg"></dt>
        <dd><h3>轮胎</h3><div class="cont">实现了以网络货运平台体系、全国汽车后市场服务为核心，遍布全国的轮胎新零售网络。</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va9.jpg"></dt>
        <dd><h3>融资租赁</h3><div class="cont">通过数据挖掘和精确营销，基于多个消费场景，明确信贷资金去向，实施资金定向支付。</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/va10.jpg"></dt>
        <dd><h3>润滑油及尿素</h3><div class="cont">形成生态圈闭环，在效率与成本之间找到平衡点，为司机提供有竞争力的集采产品。</div></dd>
      </dl>
      <!-- <dl>
        <dt><img src="../../assets/images/va6.jpg"></dt>
        <dd><h3>汽车及后市场服务</h3><div class="cont">提供电子油卡、在线数据、外请车结算卡、自有加油卡</div></dd>
      </dl> -->
    </div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subvalueAdded {
  .bg{background:url(./../../assets/images/serbg.jpg) }
  .valueAdded{ overflow: hidden; padding-bottom: 60px;
    dl{width: 584px; height: 226px; border:#dedede solid 1px;overflow: hidden; float: left; margin:0 28px 28px 0;
      dt{ float: left; width: 305px; height: 226px; overflow: hidden;}
      dd{ float: left; width: 230px; padding:33px 0 0 28px; font-size: 15px; line-height: 28px;
        h3{ font-size: 20px;color: #222; font-weight: normal;}
        .cont{color: #666; padding: 15px 0 0;}
        a{color: #0062db;}
        a:hover{color: #0062db;text-decoration:underline}
      }
    }
    dl dt img{width: 305px; height: 226px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    dl:hover dt img{width: 330px; height: 245px; margin-top: -10px; margin-left: -12px;}
    dl:nth-child(even){margin-right: 0;}
  }

}
</style>
